<template>
  <v-app>
    <div
      class="bg-body d-flex justify-center align-center"
    >
      <div class="sign-up-card d-flex">
        <div class="box">
          <div class="sign-up-card-container">
            <div class="text-center mb-11" style="margin-top: 110px">
              <v-img src="@/assets/images/logo.svg" width="310"></v-img>
            </div>
            <p class="text-14 mb-1 weight500">{{ $t("电子邮件") }}</p>
            <v-card flat color="#F2F2F2" height="38">
              <input
                v-model="params.account"
                type="text"
                placeholder="示例@mail.com"
              />
            </v-card>
            <p class="text-14 mb-1 mt-5">{{ $t("登录密码") }}</p>
            <v-card flat color="#F2F2F2" height="38" class="mb-2">
              <input
                type="password"
                placeholder="*******"
                v-model="params.password"
                @keyup.enter="loginfunc"
              />
            </v-card>
            <router-link
              to="/forget-pwd"
              class="primary--text text-decoration-underline"
              >{{ $t("忘记密码") }}?</router-link
            >
            <v-btn
              color="primary"
              class="text-capitalize font-600 mt-8 py-5"
              block
              depressed
              @click="loginfunc"
            >
              {{ $t("提交") }}
            </v-btn>
          </div>
          <div class="pt-2">
            <div class="text-center">
              <span class="text-caption text--disabled"
                >{{ $t("还没有账号") }}？<router-link
                  to="/sign-up"
                  class="primary--text font-600 text-decoration-underline"
                  >{{ $t("注册") }}</router-link
                >
              </span>
            </div>
          </div>
        </div>
        <div class="img">
          <v-img src="@/assets/images/login/loginbf.png"></v-img>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      params: {
        account: "",
        password: "",
      },
    };
  },
  mounted() {},
  methods: {
    loginfunc() {
      if(this.params.account == ''){
         this.$Toast({ content:this.$t('请输入电子邮件'), type: "error" });
         return
      }else if(this.params.password == ''){
         this.$Toast({ content:this.$t('请输入登陆密码'), type: "error" });
         return
      }
      this.$api.signIn.login(this.params).then((res) => {
        if (res.status) {
          this.$store.commit("setUSERINFO", res.data.user_info);
          this.$router.push("/view-profile");
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-up-card {
  width: 1000px;
  overflow: hidden;
  height: 570px;
  box-shadow: 0px 4px 30px 0px rgba(103, 91, 230, 0.2);
  border-radius: 20px;
  margin: auto;
  .sign-up-card-container {
    width: 310px;
    margin: auto;
  }
}
@media (max-width: 999px) {
  .sign-up-card {
    width: 450px;
  }
  .img {
    display: none;
    width: 0;
  }
}
.box {
  width: 450px;
}

.bg-body{
  width: 100%;
  height: 100%;
}
</style>